<template>
  <Toast />
  <div>
    <file-pond
      name="test"
      ref="pond"
      label-idle="Drop files here..."
      credits="false"
      maxFileSize="100MB"
      labelMaxFileSizeExceeded="El archivo es demasiado grande"
      labelMaxFileSize="El tamaño máximo del archivo es de 100MB"
      v-bind:allow-multiple="true"
      accepted-file-types="application/pdf"
      :server="{ process }"
      instantUpload="false"
      v-on:init="handleFilePondInit"
      @initfile="handleFilePontInitFile"
      data-pdf-preview-height="640"
      pdfPreviewHeight="640"
      pdfComponentExtraParams="toolbar=1&navpanes=0&scrollbar=0&view=fitV&zoom=100"
      labelTapToCancel="Toca para cancelar"
      labelButtonAbortItemLoad = "Cancelar descarga"
      labelButtonRemoveItem = "Borrar archivo"
      labelButtonRetryItemLoad="Cargando"
      labelButtonRetryItemProcessing ="Reintentar"
      labelButtonUndoItemProcessing ="Deshacer"
      labelDecimalSeparator =","
      labelFileAdded = "Archivo Añadido"
      labelFileCountPlural ="Archivos"
      labelFileCountSingular ="Archivo"
      labelFileLoadError ="Error al subir"
      labelFileLoading ="Cargando"
      labelFileProcessing ="Subiendo"
      labelFileProcessingAborted ="Subida cancelada"
      labelFileProcessingComplete ="Subida completa"
      labelFileProcessingError ="Error al subir archivo"
      labelFileRemoved="Archivo eliminado"
      labelFileSizeNotAvailable ="Tamaño no disponible"
      labelFileWaitingForSize ="Comprobando tamaño"
      labelIdle ="Arrastre un archivo o pulse aqui"
      labelTapToRetry="Toca para reintentar"
      labelTapToUndo="Toca para deshacer"
      labelThousandsSeparator=""
    />
  </div>

</template>

<script>
import { ref } from 'vue'
import axios from 'axios'
import { useToast } from 'primevue/usetoast'
import vueFilePond from 'vue-filepond'
import 'filepond/dist/filepond.min.css'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import FilePondPluginPdfPreview from 'filepond-plugin-pdf-preview'
import FilePondPluginValidateSize from 'filepond-plugin-file-validate-size'

// Create component
const FilePond = vueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginImagePreview,
  FilePondPluginPdfPreview,
  FilePondPluginValidateSize
)

export default {
  name: 'filePondUpload',
  components: {
    FilePond
  },
  props: {
    url: {
      type: String,
      default: null
    },
    licitacionId: {
      type: String,
      default: null
    }
  },
  setup (props, context) {
    console.log(props.url)
    const toast = useToast()
    const urlSave = ref(props.url)
    const pond = ref()
    const licitacionId = ref(props.licitacionId)

    const handleFilePondInit = () => {
      console.log('FilePond se ha iniciado correctamente')
    }

    const handleFilePontInitFile = () => {
      // console.log('Entre aqui....')
    }

    /**/
    const process = (fieldName, file, metadata, load, error, progress, abort, transfer, options) => {
      // Seteando la data a enviar
      const form = new FormData()

      form.append('adjuntoPliegos', file)
      form.append('licitacionId', licitacionId.value)

      // Opciones de la solicitud
      const opts = {
        method: 'POST',
        url: urlSave.value,
        data: form,
        onUploadProgress: (e) => {
          // updating progress indicator
          progress(e.lengthComputable, e.loaded, e.total)
        }
      }

      // Envio de la solicitud
      axios.request(opts).then(function (response) {
        load(response.data.id)
      }).catch(function (error) {
        console.error(error)
      })
    }

    const onSubmitSupport = async () => {
      return pond.value.processFiles().then(files => {
        if (files.length <= 0) {
          toast.add({ severity: 'warn', summary: 'Advertencia', detail: 'No hay ningún soporte seleccionado para cargar', life: 3000 })
        } else {
          toast.add({ severity: 'success', summary: 'Exito', detail: 'Se guardo exitosamente el soporte', life: 3000 })
        }
      })
    }

    const onRemoveSupport = async () => {
      return pond.value.removeFiles()
    }

    return {
      handleFilePondInit,
      handleFilePontInitFile,
      process,
      pond,
      onSubmitSupport,
      onRemoveSupport
    }
  }

}
</script>

<style scoped>

</style>
